body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
select.filter.select-filter.form-control {
  height: 200px;
}


.table thead th {
  vertical-align: top;
}

div.viewer {
  height: 60%;
}

.tab-content.active {
  display: contents;
}

.rc-slider-track {
  background-color: #007bff;
}
.rc-slider-handle {
  border: solid 2px #007bff;
}
.rc-slider-handle-click-focused:focus {
  border: solid 2px #007bff;
}
.rc-slider-handle:active {
  color: #007bff;
  border-color: #007bff;
  box-shadow: 0 0 5px #007bff;
}
.rc-slider-handle:hover {
  color: #007bff;
  border-color: #007bff;
}
.navbar-custom {
    background-color: #8C1515;
}

