.rc-slider-track {
  background-color: #007bff;
}
.rc-slider-handle {
  border: solid 2px #007bff;
}
.rc-slider-handle-click-focused:focus {
  border: solid 2px #007bff;
}
.rc-slider-handle:active {
  color: #007bff;
  border-color: #007bff;
  box-shadow: 0 0 5px #007bff;
}
.rc-slider-handle:hover {
  color: #007bff;
  border-color: #007bff;
}
.navbar-custom {
    background-color: #8C1515;
}
